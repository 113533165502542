var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"pricing_table_title"},[_vm._v("\n    "+_vm._s(_vm.$i18n('general.pricing-table-title', {
        airportName: _vm.airport.name,
      }))+"\n  ")]),_vm._v(" "),_c('table',{staticClass:"pricing_table",attrs:{"id":"locations_pricing_table"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("\n          "+_vm._s(_vm.$i18n('general.pricing-table-provider'))+"\n        ")]),_vm._v(" "),_c('th',[_vm._v("\n          "+_vm._s(_vm.$i18n('general.pricing-table-type'))+"\n        ")]),_vm._v(" "),_c('th',[_vm._v("\n          "+_vm._s(_vm.$i18n('general.pricing-table-days', { days: '8' }))+"\n        ")])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.offers),function(offer,key){return _c('tr',{key:key},[_c('td',[_c('a',{attrs:{"href":`/${_vm.slug}/${offer.merchant.slug}.html`,"data-test":"pricing-table-merchant-slug"}},[_vm._v("\n            "+_vm._s(offer.merchant.name)+"\n          ")])]),_vm._v(" "),_c('td',{attrs:{"data-test":"pricing-table-merchant-type"}},[_vm._v(_vm._s(_vm.parkingType(offer))+" | "+_vm._s(_vm.coverage(offer.roof)))]),_vm._v(" "),_c('td',{attrs:{"data-test":"pricing-table-merchant-price"}},[_vm._v("\n          "+_vm._s(offer.price
              ? _vm.$i18n('general.offer-price', {
                  price: _vm.price(offer.price, offer.currency),
                })
              : _vm.$i18n('general.offer-price-unavailable'))+"\n        ")])])}),0)]),_vm._v(" "),_c('div',{staticClass:"show_more",attrs:{"data-test":"show-more-button"}},[_c('button',{attrs:{"id":"locations_pricing_table_button"},on:{"click":_vm.loadAllRows}},[_vm._v("\n      "+_vm._s(_vm.$i18n('general.show_more'))+"\n    ")])]),_vm._v(" "),_c('p',[_vm._v("\n    "+_vm._s(_vm.$i18n('general.pricing-table-note', {
        offerArrival: _vm.offerArrival,
        offerDeparture: _vm.offerDeparture,
      }))+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }