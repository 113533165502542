<template>
  <div class="airport-page">
    <div
      style="height:0!important"
      class="
      !hidden
      font-bold
      overflow-hidden
      overflow-ellipsis
      hover:bg-blaze-orange-500
      bg-blue-500
      text-white
      px-2
      py-1
      text-center
      cursor-pointer
      whitespace-nowrap
      btn
      btn-orange
      inline-block
      bg-primary-500
      text-white
      px-3.5
      pt-2
      pb-3.5
      text-lg
      font-heading
      rounded
      shadow-button
      hover:bg-primary-600
      focus:bg-primary-700
      hover:no-underline
      hover:text-white"
      data-comment="introduced for tailwind to generate these classes which used in cms content"
    />
    <experiment-toggle name="reduce-font-size">
      <template #a />
      <template #b>
        <ReduceFontSize />
      </template>
    </experiment-toggle>

    <div class="airport-page__breadcrumbs" data-test="breadcrumbs">
      <LazyHydrate never>
        <BreadCrumbs
          v-if="navigation.length && navigation.length"
          :navigation="navigation"
        />
      </LazyHydrate>
    </div>
    <experiment-toggle name="airSpringCampaign2">
      <template #a />
      <template #b>
        <SpringBanner />
      </template>
      <template #c>
        <SpringCornerButton />
      </template>
    </experiment-toggle>
    <experiment-toggle name="airSpringCampaignNL">
      <template #a />
      <template #b>
        <NlSpringBanner />
      </template>
      <template #c>
        <NlSpringCornerButton />
      </template>
    </experiment-toggle>
    <div
      v-if="hasParkings && be604ukQuickWinsRunning"
      :class="[
        'airport-page__parking-list',
        { 'airport-page__parking-list--no-reviews': !hasReviews },
      ]"
      data-test="parking-list"
    >
      <div class="airport-page__merchant-list">
        <LazyHydrate when-idle>
          <Merchants />
        </LazyHydrate>
      </div>
    </div>

    <div>
      <template v-if="!be604ukQuickWinsRunning">
        <LazyHydrate when-idle>
          <UpToDateInformationV2 class="xs:container px-5 md:px-10" />
        </LazyHydrate>
      </template>

      <LazyHydrate v-else never>
        <be604UpToDateInformation />
      </LazyHydrate>
    </div>

    <div
      :class="[
        'airport-page__content',
        { 'airport-page__content--no-reviews': !hasReviews },
      ]"
    >
      <div
        v-if="!be604ukQuickWinsRunning"
        ref="content"
        class="airport-page__content-data"
        data-test="airport-page-content"
        v-html="airportContent"
      />
      <be604Content v-else :airport-content="airportContent" />
    </div>
    <div>
      <LazyHydrate when-idle>
        <PricingTable class="pricing_table_container" data-test="pricing-table" :slug="airport.slug" />
      </LazyHydrate>
    </div>

    <div class="airport-page__parkos_bg">
      <div v-if="hasReviews" class="airport-page__reviews-section" data-test="reviews-section">
        <LazyHydrate when-idle>
          <Reviews class="airport-page__reviews-section__carousel" />
        </LazyHydrate>
      </div>
      <div
        v-if="hasParkings && !be604ukQuickWinsRunning"
        :class="[
          'airport-page__parking-list',
          { 'airport-page__parking-list--no-reviews': !hasReviews },
        ]"
      >
        <div class="airport-page__merchant-list">
          <LazyHydrate when-idle>
            <Merchants />
          </LazyHydrate>
        </div>
      </div>
      <div
        v-if="$store.state.faq && $store.state.faq.length"
        id="locations_faq"
        class="airport-page__faq-container"
        data-test="faq-section"
      >
        <LazyHydrate v-if="!be604ukQuickWinsRunning" when-idle>
          <Faq class="py-10 md:py-20" />
        </LazyHydrate>
        <LazyHydrate v-else when-idle>
          <be604Faq class="py-10 md:py-20" />
        </LazyHydrate>
      </div>
      <div class="bg-white">
        <InternalLinks />
      </div>
    </div>
    <div
      v-if="!be604ukQuickWinsRunning"
      id="airports"
      class="border-t border-[#e5e7eb]"
    >
      <div class="airport-page__usps-container" data-test="ups-container">
        <LazyHydrate never>
          <Usps class="py-10 md:py-20" />
        </LazyHydrate>
      </div>
    </div>
    <LazyHydrate never :trigger-hydration="showZendesk && !isMobile">
      <HelpButton
        :language="language.lang"
      />
    </LazyHydrate>
    <client-only>
      <noscript>
        <iframe
        v-if="cheqDomains[$paths.host]"
        :src="cheqDomains[$paths.host]?.nojs"
        width="0"
        height="0"
        style="display: none"
        ></iframe>
      </noscript>
    </client-only>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import cheqDomains from '~/constants/cheq';
import ReduceFontSize from '~/components/experiments/ReduceFontSize.vue';
import ExperimentToggle from '~/components/experiments/ExperimentToggle.vue';
import be604UpToDateInformation from '@/components/experiments/be-604-uk-quick-wins/be-604-up-to-date-information.vue';
import be604Faq from '@/components/experiments/be-604-uk-quick-wins/be-604-faq.vue';
import be604Content from '@/components/experiments/be-604-uk-quick-wins/be604Content.vue';
import PricingTable from '@/components/PricingTable.vue';
import SpringBanner from '~/components/experiments/tr-652-de-spring-campaign/SpringBanner.vue';
import SpringCornerButton from '~/components/experiments/tr-652-de-spring-campaign/SpringCornerButton.vue';
import NlSpringBanner from '~/components/experiments/tr-664-nl-spring-campaign/SpringBanner.vue';
import NlSpringCornerButton from '~/components/experiments/tr-664-nl-spring-campaign/SpringCornerButton.vue';
import { getCookie, nginxDecodeUserId } from '~/helpers/cookieHelpers';
import InternalLinks from '~/components/experiments/tr-899-internal-link-structure/InternalLinks.vue';
import { JSONLDExtractionError } from '~/helpers/ExtractSchemaValuesError';

const BreadCrumbs = () => import('~/components/BreadCrumbs.vue');
const Merchants = () => import('~/components/merchants/Merchants.vue');
const Faq = () => import('~/components/faq/Faq.vue');
const Usps = () => import('~/components/usps/Usps.vue');
const UpToDateInformationV2 = () => import('~/components/airport/UpToDateInformationV2.vue');
const Reviews = () => import('~/components/reviews/Reviews.vue');
const HelpButton = () => import('~/components/airport/HelpButton.vue');
// Language mapping for special domains with alternative languages
const specialDomainLanguages = {
  'de-ch.parkos.com': 'dv', // Divehi
  'eu.parkos.com': 'eo', // Esperanto
  'fr-ch.parkos.com': 'fur', // Friulian
  'fr.parkos.be': 'ff', // Fulah
  'it-ch.parkos.com': 'smn', // Inari Sami
  'parkos.com.au': 'ebu', // Embu
  'parkos.co.uk': 'ee', // Ewe
};
export default {
  components: {
    HelpButton,
    BreadCrumbs,
    UpToDateInformationV2,
    Reviews,
    Merchants,
    Faq,
    Usps,
    LazyHydrate,
    ExperimentToggle,
    ReduceFontSize,
    be604UpToDateInformation,
    be604Faq,
    be604Content,
    PricingTable,
    SpringBanner,
    SpringCornerButton,
    NlSpringBanner,
    NlSpringCornerButton,
    InternalLinks,
  },
  layout: 'search',
  data() {
    return {
      be604ukQuickWinsRunning: false,
    };
  },
  head() {
    if (this.airport === null) {
      return {};
    }
    try {
      const links = [
        {
          rel: 'canonical',
          href: `https://${this.$paths.host}${this.$route.path}`,
        },
      ];

      // add alternate language links
      Object.entries(this.airportDataLanguages).forEach(([lang, content]) => {
        links.push({
          rel: 'alternate',
          hreflang: lang === 'en-eu' ? 'en' : lang,
          href:
            (!content.url.includes('https://') ? 'https://' : '')
            + String(content.url),
        });
      });
      const script = [
        {
          hid: 'datalayer',
          innerHTML: `
        var dataLayer = [{
          airportDevTitle: '${this.airport.devtitle}',
          airportSlug: '${this.airport.slug}',
          airportName: '${this.airport.name}',
          pageType: 'locations',
          domainName: '${this.$paths.host}',
          token: '504Z8p2vH6TtWX7BJC0rYaArFl9sYKdAHfisTESx',
          cId: '',
        }];

        var cId = document.cookie.match(/cId=([^ ;]*);?/);
        var ul = 0; var ps = '[]';
        if (!(cId === null && typeof cId === "object")) {
          dataLayer[0].cId = cId[1];
        }
      `,
          type: 'text/javascript',
        },
      ];

      if (this.$store?.getters?.experimentVariant('fullstory') === 'b') {
        script.push({
          src: '/_nuxt_airport_page_v2/fullstory.js',
          body: false,
        });
      }
      if (process.env.DATA_ANALYTICS_BUCKET === '1') {
        const bucketSourcePrefix = process.env.NODE_ENV === 'production' ? '/_nuxt_airport_page_v2' : '';
        const bucketSource = `${bucketSourcePrefix}/da-bucket/dist/airport-page/index.js`;

        script.push({
          src: bucketSource,
          body: true,
        });
      }

      if (this.$paths.host === 'parkos.nl') {
        const attrs = {
          src: `https://www.googletagmanager.com/gtag/js?id=${this.$config.gtagTrackingId}&l=tagManagerDataLayer`,
          async: true,
        };
        script.push(attrs);
      }

      // CHEQ
      if (this.$paths.host in cheqDomains) {
        script.push({
          src: cheqDomains[this.$paths.host]?.js,
          'data-ch': this.$paths.host,
          class: cheqDomains[this.$paths.host]?.class,
          async: true,
          'data-jsonp': 'onCheqResponse',
        });
      }

      if (
        this.$store?.getters?.experimentVariant('BE1356CookieBanner') === 'b'
      ) {
        script.push({
          id: 'Cookiebot',
          src: 'https://consent.cookiebot.com/uc.js',
          'data-cbid': 'ea7b04b3-d681-4591-99a4-50bdcf5fa1d5',
          'data-culture': this.getCookiebotLanguage(this.$store.state.language.domain, this.$store.state.language.lang),
          type: 'text/javascript',
          async: true,
        });
      }

      return {
        title: this.airportDataContent?.meta?.title,
        htmlAttrs: {
          lang: this.getHrefLang,
        },
        meta: [
          {
            'http-equiv': 'content-language',
            content: this.getHrefLang,
          },
          {
            property: 'og:title',
            content: this.airportDataContent?.meta?.title,
          },
          {
            name: 'description',
            content: this.airportDataContent?.meta?.description,
          },
          {
            property: 'og:description',
            content: this.airportDataContent?.meta?.description,
          },
          { name: 'twitter:card', content: 'summary' },
          {
            name: 'twitter:title',
            content: this.airportDataContent?.meta?.title,
          },
          {
            name: 'twitter:site',
            content: '@ParkosInt',
          },
          {
            name: 'twitter:creator',
            content: '@ParkosInt',
          },
          {
            name: 'twitter:description',
            content: this.airportDataContent?.meta?.description,
          },
          {
            name: 'twitter:image',
            content: this.getImageUrl,
          },
          { property: 'og:type', content: 'place' },
          {
            property: 'og:locale',
            content: this.getHrefLang,
          },
          {
            property: 'place:location:latitude',
            content: String(this.airport.address.latitude),
          },
          {
            property: 'place:location:longitude',
            content: String(this.airport.address.longitude),
          },
          {
            property: 'og:image',
            content: this.getImageUrl,
          },
          {
            property: 'og:url',
            content: this.$paths.url(false) + this.$route.path,
          },
        ],
        link: links,
        script,
        __dangerouslyDisableSanitizersByTagID: {
          datalayer: ['innerHTML'],
        },
      };
    } catch (error) {
      console.error(`Error in head() method: ${error.message}`);
      if (
        error instanceof TypeError
        && error.message.includes('null is not an object')
      ) {
        throw new JSONLDExtractionError(
          'Failed to extract schema values from JSONLD',
        );
      } else {
        throw error; // rethrow other errors
      }
    }
  },
  computed: {
    isUSDomain() {
      return this.$store.state.language.lang === 'en-us';
    },
    cheqDomains() {
      return cheqDomains;
    },
    airportDataContent() {
      return this.airportData.content || {};
    },
    reviews() {
      let reviewsForJsonLD = [];
      reviewsForJsonLD = this.$store.state.reviews.map((review) => {
        const ModifiedReview = {
          '@type': 'Review',
          author: {
            '@type': 'Person',
            name: review?.name,
          },
          datePublished: review?.date ?? '',
          reviewBody: review?.content ?? '',
          reviewRating: {
            '@type': 'Rating',
            bestRating: this.testedAirportsWithPercentScale
              ? 100
              : this.isUSDomain
                ? 5
                : 10,
            ratingValue: this.reviewScoreValue(review.score),
            worstRating: 1,
          },
        };
        reviewsForJsonLD.push(ModifiedReview);
        return ModifiedReview;
      });
      return reviewsForJsonLD;
    },
    airportDataLanguages() {
      return this.$store.state.airportDataContentLanguages;
    },
    hasParkings() {
      return this.$store.state.parkings && this.$store.state.parkings.length;
    },
    hasReviews() {
      return (
        this.reviewsMeta
        && this.reviewsMeta.reviews
        && this.reviewsMeta.reviews.count
      );
    },
    gtmKey() {
      return this.language.gtm_key;
    },
    FAQ() {
      return this.$store.state.faq;
    },
    reviewsMeta() {
      return this.$store.state.reviewsMeta;
    },
    airport() {
      return this.$store.state.airport;
    },
    language() {
      return this.$store.state.language;
    },
    navigation() {
      return [
        {
          href: '/',
          title: 'Home',
        },
        { href: `/${this.airport.slug}/`, title: this.airport.maintitle },
      ];
    },
    airportContent() {
      if (!this.$store.state.airportData.content) {
        return null;
      }
      return this.$store.state.airportData.content.content;
    },
    airportData() {
      return this.$store.state.airportData;
    },
    maxRating() {
      if (this.language.lang === 'en-us') {
        return 5;
      }
      if (this.testedAirportsWithPercentScale) {
        return 100;
      }
      return 10;
    },
    totalRating() {
      if (this.language.lang === 'en-us') {
        return (this.reviewsMeta.reviews.score / 2)
          .toFixed(1)
          .replace(/[.,]0$/, '');
      }
      if (this.testedAirportsWithPercentScale) {
        return `${(
          Number(
            this.reviewsMeta.reviews.score.toFixed(1).replace(/[.,]0$/, '') / 10,
          ) * 100
        ).toFixed(0)}%`;
      }
      return this.reviewsMeta.reviews.score.toFixed(1).replace(/[.,]0$/, '');
    },
    getOffers() {
      const pricePerDay = this.$store.state.airportData?.airportExtendedData?.avg_price_per_day;
      const highestPrice = Number(this.highestPriceMerchant);
      const reviewCount = this.reviewsMeta?.reviews?.count;
      let offerSchema = {
        '@type': 'AggregateOffer',
        priceCurrency: this.language.currency.iso_code
          ? this.language.currency.iso_code
          : 'EUR',
        lowPrice: pricePerDay,
      };
      if (highestPrice && process.env.ENABLE_EXPERIMENT_TR_674 === '1') {
        offerSchema.highPrice = highestPrice;
      }
      if (!pricePerDay && !reviewCount) {
        offerSchema.lowPrice = '0.79';
      } else if (!pricePerDay) {
        offerSchema = null;
      }

      return { offers: offerSchema };
    },
    highestPriceMerchant() {
      const available = Object.values(
        this.$store.state.parkingsOffers.available,
      );

      const eigthDaysHighestPrice = Math.max(
        ...available.map((item) => item.price || 0),
      );
      const highestPrice = eigthDaysHighestPrice / 8;
      return Number(highestPrice).toFixed(2);
    },
    showZendesk() {
      return this.$store.state.zendeskEnabled;
    },
    isMobile() {
      if (process.server) {
        return true;
      }
      return window.innerWidth <= 576 || window.innerHeight <= 576;
    },
    getHrefLang() {
      return this.language.lang === 'en-eu' ? 'en' : this.language.lang;
    },
    testedAirportsWithPercentScale() {
      let slugs = [];
      if (this.$paths.host === 'parkos.co.uk') {
        slugs = [
          'gatwick-parking',
          'stansted-airport-parking',
          'heathrow-parking',
          'manchester-airport-parking',
        ];
      }
      return slugs.includes(this.$route.params.airport);
    },
    testedAirportsWithXVotes() {
      const slugs = [
        'parken-flughafen-frankfurt',
        'parken-flughafen-hamburg',
        'parken-flughafen-stuttgart',
        'parken-flughafen-hannover',
        'parken-flughafen-bremen',
      ];
      return slugs.includes(this.$route.params.airport);
    },
    getImageUrl() {
      return `${this.$paths.assetsUrl}img/locations/${this.airport.devtitle}.jpg`;
    },
  },
  created() {
    this.be604ukQuickWinsRunning = this.$experimentVariant('airUkQuickWins') === 'b';
    if (process.client) {
      // eslint-disable-next-line nuxt/no-globals-in-created
      window.addEventListener('error', (error) => {
        try {
          // prevent doublefault
          this.$store.commit('bucket/update', {
            key: 'error_line',
            value: error.lineno,
          });
          this.$store.commit('bucket/update', {
            key: 'error_url',
            // eslint-disable-next-line no-restricted-globals
            value: location.href,
          });
          this.$store.commit('bucket/update', {
            key: 'error_message',
            value: error.message,
          });
        } catch (ex) {}
      });
    }
  },
  async mounted() {
    // eslint-disable-next-line no-new
    new Promise((resolve) => {
      const handler = setInterval(() => {
        if (typeof FS !== 'function') {
          return;
        }
        // eslint-disable-next-line no-undef
        FS('setProperties', {
          type: 'page',
          properties: {
            pageName: 'locations',
          },
        });
        clearTimeout(handler);
      }, 1000);
      setTimeout(() => {
        clearTimeout(handler);
      }, 5000);
      resolve();
    });
    await this.$store.dispatch('experimentVariant', {
      name: 'aa-test-be-1772-airport-nuxt-2',
    });
    await this.$store.dispatch('experimentVariant', {
      name: 'BE1356CookieBanner',
    });
    window.addEventListener('CookiebotOnDialogDisplay', () => {
      window.dataLayer.push({
        event: 'experiment_data',
        dl_experiment_id: 'BE-1356',
        dl_experiment_action: 'impression',
        dl_experiment_value: 'banner',
      });
      const configure = document.querySelector(
        '#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection',
      );
      const selectAll = document.querySelector(
        '#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll',
      );

      setTimeout(() => {
        selectAll?.addEventListener('click', (e) => {
          setTimeout(() => {
            window.dataLayer.push({
              event: 'experiment_data',
              dl_experiment_id: 'BE-1356',
              dl_experiment_action: 'selection',
              dl_experiment_value: 'accept_all',
            });
            this.sendEvent('accept_all', 'Action Tracking', 'cookieBanner');
          });
        });

        configure?.addEventListener('click', () => {
          setTimeout(() => {
            window.dataLayer.push({
              event: 'experiment_data',
              dl_experiment_id: 'BE-1356',
              dl_experiment_action: 'selection',
              dl_experiment_value: 'accept_selection',
            });
            this.sendEvent(
              'accept_selection',
              'Action Tracking',
              'cookieBanner',
            );
          });
        });
      });
      window.addEventListener(
        'CookiebotOnDecline',
        () => {
          if (window.Cookiebot.changed) {
            window.dataLayer.push({
              event: 'experiment_data',
              dl_experiment_id: 'BE-1356',
              dl_experiment_action: 'selection',
              dl_experiment_value: 'decline_all',
            });
            this.sendEvent('decline_all', 'Action Tracking', 'cookieBanner');
          }
        },
        false,
      );
    });

    if (this.$paths.host === 'parkos.nl') {
      window.tagManagerDataLayer = window.tagManagerDataLayer || [];
      function gtag() {
        tagManagerDataLayer.push(arguments);
      }

      gtag('js', new Date());

      gtag('config', process.env.GTAG_TRACKING_ID);
    }
    this.setSessionCookies();
  },
  methods: {
    // Enhanced method to get the appropriate language for Cookiebot
    getCookiebotLanguage(currentDomain, currentLanguage) {
      // Check if the current domain needs a special language
      if (specialDomainLanguages[currentDomain]) {
        return specialDomainLanguages[currentDomain];
      }

      // Use the existing removeExtractLanguage method
      return this.removeExtractLanguage(currentLanguage);
    },

    // Existing method to remove language region
    removeExtractLanguage(languageCode) {
      const parts = languageCode.split('-');

      if (parts.length === 1) {
        return languageCode;
      }
      return parts[0];
    },
    async sendEvent(ea, ec, el) {
      const { host } = window.location;
      const pageType = () => window.$nuxt?.$store?.state?.bucket?.page_type || window.pageType;

      const visitorIdCookie = getCookie('uid');
      if (!visitorIdCookie) {
        console.log('[FLAGSHIP GTM] VisitorId is not defined');
        return;
      }
      const visitorId = nginxDecodeUserId(visitorIdCookie);
      const flagshipEnvironmentId = this.$flagshipEnvironmentId;
      const flagshipEventsAPIEndpoint = this.$config.FLAGSHIP_EVENTS_API_BASE_URL;

      const response = await fetch(
        `${flagshipEventsAPIEndpoint}?host=${host}&app=${pageType()}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            cid: flagshipEnvironmentId,
            vid: visitorId,
            dl: document.location.href,
            ds: 'APP',
            ev: 1,
            t: 'EVENT',
            ea,
            ec,
            el,
          }),
        },
      );
      if (response.status !== 200) {
        console.error(
          `[FLAGSHIP GTM] Event could not be be sent, HTTP ${response.status}`,
        );
      }
    },
    handleReferralCashbackCookie() {
      let cookie = this.getCookie('referral_cashback');
      if (cookie) {
        cookie = JSON.parse(decodeURIComponent(cookie));
      }
      this.$store.commit('referralCashback/set', cookie);
    },
    setSessionCookies() {
      // Tries to set session cookies based on query params and after that retries pushing the cIdto the datalayer if its set in the cId cookie
      const params = this.$route.query;

      this.$axios({
        method: 'get',
        baseURL: '/',
        url: 'ajax/set-session-cookie',
        params,
      })
        .then((result) => {
          window.dataLayer.push({
            cId: this.getCookie('cId'),
          });
        })
        .catch((error) => {
          // Add error handeling for failing session cookie links
          console.log(error);
        })
        .finally(() => {
          this.handleReferralCashbackCookie();
        });
    },
    getCookie(name) {
      const cookie = `; ${document.cookie}`;
      const cookieParts = cookie.split(`; ${name}=`);
      if (cookieParts.length === 2) return cookieParts.pop().split(';').shift();
      return '';
    },
    reviewScoreValue(score) {
      if (this.isUSDomain) {
        return parseFloat((score / 2).toFixed(1));
      }
      if (this.testedAirportsWithPercentScale) {
        return `${((Number(+score) / 10) * 100).toFixed(0)}%`;
      }
      return +score;
    },
  },
  jsonld() {
    const graph = [];

    // Breadcrumbs
    const breadcrumbList = {
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': `/${this.airport.slug}/#`,
            name: this.airport.maintitle,
          },
        },
      ],
    };
    graph.push(breadcrumbList);

    const faqEntities = !this.FAQ
      ? null
      : this.FAQ.map((f) => ({
        '@type': 'Question',
        name: f.meta.title,
        acceptedAnswer: {
          '@type': 'Answer',
          text: `${f.content}`,
        },
      }));

    const faqPage = {
      '@type': 'FAQPage',
      mainEntity: faqEntities,
    };

    if (faqEntities && faqEntities.length) graph.push(faqPage);

    // Product
    const productSchema = {
      '@type': 'Product',
      name: this.airport.maintitle,
      image: this.getImageUrl,
      description: this.airportDataContent?.meta?.description,
      brand: {
        '@type': 'Brand',
        name: `${this.airport.name} ${this.airport.object}`,
      },
      ...(this.getOffers || {}),
      ...(this.reviewsMeta?.reviews?.count
        ? {
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: this.totalRating,
            bestRating: this.maxRating,
            worstRating: 1,
            reviewCount:
                !this.testedAirportsWithXVotes
                && !this.testedAirportsWithPercentScale
                  ? this.reviewsMeta.reviews.count
                  : undefined,
            ratingCount:
                this.testedAirportsWithXVotes
                || this.testedAirportsWithPercentScale
                  ? this.reviewsMeta.reviews.count
                  : undefined,
          },
        }
        : {}),
      review: [...this.reviews],
    };
    graph.push(productSchema);

    return { '@context': 'http://schema.org', '@graph': graph };
  },
};
</script>

<style lang="scss">
.airport-page {
  @apply bg-white leading-relative;

  &__breadcrumbs {
    @apply xs:container px-5 sm:px-10 overflow-auto whitespace-nowrap;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__content {
    @apply mt-5 flex flex-col md:flex-row xs:container px-5 sm:px-10 md:place-content-between;
    content-visibility: auto;

    &--no-reviews {
      @apply pb-10 md:pb-20;
    }
  }

  &__parkos_bg {
    @apply bg-no-repeat;
    background-color: #f9fbfe;
    background-image: url(https://assets.parkos.com/assets/img/parkinglot_background.svg);
  }

  &__reviews-section {
    &__carousel {
      @apply xs:container px-5 sm:pl-10;
    }
  }

  &__parking-list {
    &--no-reviews {
      @apply pt-10 md:pt-20;
    }
  }

  &__merchant-list {
    @apply xs:container px-5 sm:px-10;

    .merchants {
      @apply pb-10 md:pb-20;
    }
  }

  &__usps-container,
  &__faq-container {
    @apply xs:container px-5 sm:px-10;
  }
}

//temporary solution
.cms-content {
  .divider:not([data-ignore-styling]) {
    display: none;
  }

  .parking-airport:not([data-ignore-styling]) {
    h2.h1 {
      @apply text-lg md:text-xl;
    }

    .lg\:w-3\/12 {
      @media (min-width: 768px) {
        @apply w-4/12;
      }
    }
  }

  .type-parking-airport:not([data-ignore-styling]) {
    .row.-mx-6 {
      display: flex;
      @media (max-width: 768px) {
        flex-wrap: wrap;
      }
    }
  }

  .lg\:w-1\/12:not([data-ignore-styling]) {
    @media (min-width: 768px) {
      @apply w-2/12;
    }
    @apply w-3/12;
  }
  .col-xs-4:not([data-ignore-styling]).px-6:not([data-ignore-styling]) {
    @apply px-2;
  }
  .row:not([data-ignore-styling]) {
    @apply flex flex-nowrap my-2;

    & > .col-xs-4 {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
    @media (max-width: 768px) {
      @apply flex-wrap place-content-center mt-8;
    }
  }

  h2:not([data-ignore-styling]) {
    @apply mb-5 text-lg md:text-xl;
  }
  h1:not([data-ignore-styling]),
  h3:not([data-ignore-styling]) {
    margin-top: 30px !important;
  }
  .md\:py-24:not([data-ignore-styling]) {
    @apply py-10 pt-0;
  }
  br:not([data-ignore-styling]) + br {
    @apply hidden;
  }
  .pt-12:not([data-ignore-styling]),
  .pb-12:not([data-ignore-styling]) {
    @apply pt-3 pb-3;
  }
  .sm\:w-1\/2:not([data-ignore-styling]) {
    @apply w-full;
  }

  .btn-orange:not([data-ignore-styling]) {
    @apply bg-blaze-orange-500 cursor-pointer py-2;
    &:hover {
      @apply bg-blaze-orange-400;
    }
  }
  h1:not([data-ignore-styling]),
  h2:not([data-ignore-styling]),
  h3:not([data-ignore-styling]),
  h4:not([data-ignore-styling]),
  h5:not([data-ignore-styling]) {
    @apply font-cocogoose;
  }
  @apply border-athens;
  .border-gray-500:not([data-ignore-styling]) {
    @apply border-athens;
  }
  ul#breadcrumb:not([data-ignore-styling]) {
    @apply hidden;
    + br {
      @apply hidden;
    }
  }
}
.footer {
  .footer-icons {
    & + div {
      @apply hidden;
    }
  }
}

#trustbadge-container-98e3dadd90eb493088abdc5597a70810 {
  z-index: 39 !important;
  ._12n8yed {
    margin-bottom: 30px !important;
  }
}

.hidden.md\:block.w-4\/12 {
  min-width: 36%;
}
</style>
