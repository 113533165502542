<template>
  <div>
    <h2 class="pricing_table_title">
      {{
        $i18n('general.pricing-table-title', {
          airportName: airport.name,
        })
      }}
    </h2>
    <table class="pricing_table" id="locations_pricing_table">
      <thead>
        <tr>
          <th>
            {{ $i18n('general.pricing-table-provider') }}
          </th>
          <th>
            {{ $i18n('general.pricing-table-type') }}
          </th>
          <th>
            {{ $i18n('general.pricing-table-days', { days: '8' }) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(offer, key) in offers" :key="key">
          <td>
            <a :href="`/${slug}/${offer.merchant.slug}.html`" data-test="pricing-table-merchant-slug">
              {{ offer.merchant.name }}
            </a>
          </td>
          <td data-test="pricing-table-merchant-type">{{ parkingType(offer) }} | {{ coverage(offer.roof) }}</td>
          <td data-test="pricing-table-merchant-price">
            {{
              offer.price
                ? $i18n('general.offer-price', {
                    price: price(offer.price, offer.currency),
                  })
                : $i18n('general.offer-price-unavailable')
            }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="show_more" data-test="show-more-button">
      <button id="locations_pricing_table_button" @click="loadAllRows">
        {{ $i18n('general.show_more') }}
      </button>
    </div>
    <p>
      {{
        $i18n('general.pricing-table-note', {
          offerArrival: offerArrival,
          offerDeparture: offerDeparture,
        })
      }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    slug: undefined,
  },

  computed: {
    airport() {
      return this.$store.state.airport;
    },
    language() {
      return this.$store.state.language;
    },
    offers() {
      const available = Object.values(
        this.$store.state.parkingsOffers.available
      );
      const unavailable = Object.values(
        this.$store.state.parkingsOffers.unavailable
      );

      // Sort available offers, placing merchants with 0 or undefined price at the end
      const sortedAvailable = available.sort((a, b) => {
        if (!a.price || a.price === 0) return 1;
        if (!b.price || b.price === 0) return -1;
        return a.price - b.price;
      });

      return [...sortedAvailable, ...unavailable];
    },
    offerArrival() {
      const offerArrival = this.$store.state.offerArrival;
      return this.formatDate(offerArrival);
    },
    offerDeparture() {
      const offerDeparture = this.$store.state.offerDeparture;
      return this.formatDate(offerDeparture);
    },
  },
  methods: {
    price: function (price, currency) {
      return new Intl.NumberFormat(this.$locale, {
        style: 'currency',
        currency,
      }).format(price);
    },
    formatDate(date) {
      let day = new Date(date).toLocaleDateString(this.language.lang, {
        weekday: 'long',
      });
      if (this.language.lang === 'pl' && day === 'piątek') day = 'pt';
      return `${day.substr(0, day.length < 2 ? day : 2)}. ${Intl.DateTimeFormat(
        this.formatLangForDate(this.language.lang),
        this.dateFormating
      ).format(new Date(date))}`;
    },
    formatLangForDate(lang) {
      if (lang === 'nl-be' || lang === 'en-eu') {
        return lang === 'nl-be' ? 'nl' : 'de';
      }
      return lang;
    },
    dateFormating() {
      const isAtDomain = this.language?.lang === 'de-at';
      return isAtDomain
        ? { day: 'numeric', month: 'numeric', year: 'numeric' }
        : { day: '2-digit', month: '2-digit', year: 'numeric' };
    },
    parkingType(offer) {
      if (
        ['walking', 'train'].includes(offer.merchant?.properties?.transfer_type)
      ) {
        return this.$i18n('general.parking');
      }
      return this.$i18n(`search.grid.${offer.parking_type}-parking`);
    },
    coverage(roof) {
      if (roof) {
        return this.$i18n('general.indoor-type');
      }
      return this.$i18n('general.outside-type');
    },
    loadAllRows() {
      var rows = document.querySelectorAll('.pricing_table_container table tr');
      var lastActiveIndex = 8;
      rows.forEach(function (row) {
        if (row.classList.contains('active')) {
          lastActiveIndex = row.rowIndex;
        }
      });
      for (
        var i = 0;
        i <
        document.querySelectorAll('.pricing_table_container table tr').length;
        i++
      ) {
        if (rows[lastActiveIndex + i]) {
          rows[lastActiveIndex + i].classList.add('active');
        }
      }
      const showMoreButton = document.querySelector('.show_more');
      if (showMoreButton) showMoreButton.remove();
    },
  },
  mounted() {
    this.$nextTick(() => {
      const pricingTableContainer = document.querySelector(
        '.pricing_table_container'
      );
      const parkgebuehrenTable = document.querySelector(
        '.cms-content .parking-airport'
      );

      if (pricingTableContainer && parkgebuehrenTable) {
        parkgebuehrenTable.after(pricingTableContainer);
      }

      document
        .querySelectorAll('table tr:nth-child(n+1):nth-child(-n+10)')
        .forEach(function (row) {
          row.classList.add('active');
        });
    });

    //if the offers are less than 10 than don't show the `show more` button
    if (this.offers.length < 10) {
      this.loadAllRows();
    }
  },
};
</script>

<style lang="scss" scoped>
.pricing_table_title {
  @apply md:text-xl text-lg mb-8 mt-5 font-cocogoose;
}
.pricing_table {
  @apply w-screen md:w-full -ml-5 md:ml-0 border border-spacing-0 border-separate rounded overflow-scroll text-justify border-athens-500;
  table-layout: fixed; /* Ensure equal width for all columns */

  thead {
    tr {
      @apply bg-silver-100 break-normal text-left border-athens-500 border-b;
      //border-bottom: 1px solid #ddd;
      @apply md:grid-cols-none;
      @apply grid md:table-row md:grid-cols-none;
      grid-template-columns: 1.7fr 0.1fr 1fr;

      th {
        @apply text-left p-1 lg:pl-6 lg:p-3;
        &:first-child {
          @apply pl-5 md:pl-6;
          grid-area: 1 / 1;
        }

        &:nth-child(2) {
          @apply hidden md:block;
          grid-area: 2/ 1;
        }

        &:nth-child(3) {
          @apply pr-0 md:pr-[10px] md:text-left md:pl-6;
          grid-area: 1 / 3;
        }
      }
    }
  }

  tbody {
    tr {
      //border-bottom: 1px solid #ddd;
      @apply md:grid-cols-none border-athens-500 border-b;
      grid-template-columns: 1.7fr 0.1fr 1fr;
      td {
        @apply p-1 lg:pl-6 lg:px-3 lg:py-2 md:border-b text-base text-left;
        &:first-child {
          grid-area: 1 / 1;
          @apply pt-[6px] pl-5 md:p-2 md:pl-6;
        }
        &:nth-child(2) {
          grid-area: 2/ 1;
          @apply text-xs leading-6 pl-5 md:text-[1rem] pr-0 pb-[6px] md:p-2 md:pl-6;
        }
        &:last-child {
          grid-area: 1 / 3;
          @apply pt-[6px] pl-0 md:p-2 md:pl-6;
        }
        a {
          @apply text-blue-600;
          &:hover {
            @apply underline;
          }
        }
      }
    }
  }
}
.show_more {
  @apply bg-white mb-12 text-center flex justify-center relative h-[200px] w-[110%] md:w-[100%] -ml-5 md:ml-0 items-end mt-[-177px];
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgb(255 255 255)
  );

  button {
    @apply text-[15px] font-cocogoose text-blue-600 py-4 px-6 rounded border bg-white border-blue-600 hover:bg-blue-600 hover:text-white duration-200;
    line-height: 1;
  }
}

table tr {
  display: none;
}

table tr.active {
  @apply grid md:table-row;
}

.airport-page__content > p {
  @apply font-bold text-[#828282] text-[85%] mb-12;
}
</style>
