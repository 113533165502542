<template>
  <section class="additional-info" data-test="additional-info-section">
    <h2 class="additional-info__title">
      {{ $i18n('location.additional-info', { airportName }) }}
    </h2>
    <p class="additional-info__description">
      {{ $i18n('location.internal-links-description', { airportName }) }}
    </p>

    <div class="additional-info__links-grid" data-test="additional-info-link">
      <div
        v-for="(link, index) in internalLinks"
        :key="index"
        class="additional-info__link-card"
        data-test="additional-info-link-card"
      >
        <h3 class="additional-info__link-label">
          <span class="additional-info__link-type">{{
            $i18n(`location.internal-links-${getLinkType(link.type)}`)
          }}</span>
        </h3>
        <a
          class="additional-info__link additional-info__link--desktop"
          :href="`${link.url}`"
          >{{ link.title }}</a
        >
        <p
          class="additional-info__link-title additional-info__link-title--mobile"
        >
          {{ link.title }}
        </p>
        <a
          class="additional-info__read-more additional-info__read-more--mobile"
          :href="`${link.url}`"
          >{{ $i18n('general.read-more') }}</a
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      whiteLabelTypes: [
        'route-planner',
        'long-parking',
        'reviews',
        'locationcompare',
        'business-parking',
        'valet-parking',
        'shuttle-parking',
        'park-sleep-fly',
        'parking-options',
        'blog-post',
      ],
    };
  },
  computed: {
    internalLinks() {
      return this.$store.state.internalLinks;
    },
    airportName() {
      return this.$store.state.airport.name;
    },
  },
  methods: {
    getLinkType(type) {
      return this.whiteLabelTypes.includes(type) ? type : 'other';
    },
  },
};
</script>

<style lang="scss" scoped>
.additional-info {
  @apply xs:container px-5 sm:px-10 py-10 md:py-20;

  &__title {
    @apply text-basenf md:text-xlnf font-normal mb-2.5 md:mb-4;
  }

  &__description {
    @apply text-black-alt-500 mb-6 font-sans text-15 md:text-basenf;
  }

  &__links-grid {
    @apply grid grid-cols-1 md:grid-cols-3 md:gap-5;
  }

  &__link-card {
    @apply bg-white py-5 px-7.5 mb-2.5 md:mb-0 no-underline rounded-md border border-gray-200 transition-colors duration-200;

    &:hover {
      @apply bg-cornflower-75 cursor-pointer border border-blue-600;

      .additional-info__link-type {
        @apply bg-white;
      }

      .additional-info__link,
      .additional-info__read-more {
        @apply text-blue-600;
      }
    }
  }

  &__link,
  &__link-title {
    @apply text-black-alt-500 no-underline text-mdnf md:text-basenf;
  }

  &__link {
    &--desktop {
      @apply hidden md:block transition-colors duration-200;
    }
  }

  &__link-title {
    &--mobile {
      @apply md:hidden;
    }
  }

  &__read-more {
    @apply text-blue-600;

    &--mobile {
      @apply md:hidden;
    }
  }

  &__link-label {
    @apply font-semibold mb-2;
  }

  &__link-type {
    @apply text-sm bg-gray-100 px-2.5 py-1 rounded-sm transition-colors duration-200;
  }
}
</style>
